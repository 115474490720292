export const CheckLogin = {
  beforeMount() {
    if (localStorage.login == "true") {
      // this.$router.push("/dashboard");
      if (this.$router.currentRoute.query.redirectTo && this.$router.currentRoute.query.redirectTo != undefined) {
        this.$router.push({
          path: `/` + this.$router.currentRoute.query.redirectTo
        });
      } else {
        this.$router.push({ path: `/` });
      }
    }
  }
};
